.multiselect {
  // width: 200px;
}

.sectionHeader {
  font-size: 14px;
  font-weight: bold;
}

.head {
  padding-bottom: 10px;
}

.selectBox {
  position: relative;
  // height: 13px;
  //  width:322px;
}

.selectBox:hover {
  cursor: pointer;
}

.optionDiv {
  //  width: 100%;
  //  color: grey;
  height: 14px;
  border: 0;
  border-bottom: 1px solid #9e9e9e80;
  // border-radius: 7px  ;
  opacity: 1;
  //     width: 322px  ;
  //      height: 48px  ;
  font-size: 13px;
  margin: 2px 0px;
  padding-left: 4px;
  padding-top: 12px;
  padding-bottom: 6px;
}

.arrowSelect {
  position: absolute;
  right: 4px;
  top: 16.5px;
  cursor: pointer;
  border: solid#4A4A4A;
  border-width: 0 2px 2px 0;
  padding: 3px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

// .hide {
//   display: none;
// }

.toggleOptions {
  z-index: 5;
  background-color: white;
  width: 100%;
  //   width: 322px  ;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #00000057;
  // margin-top: 21px;
}

.toggleOptions label {
  //  display: block;
  font-size: 13px;
  //   font-family: "Roboto", -apple-system, "Lucida Grande", Lucida, Arial, Verdana,
  //     sans-serif  ;
}

.toggleOptions {
  display: none;
  position: absolute;
  //   width:200px;
}

.toggleOptions label:hover {
  /* background-color: rgba(244, 244, 244, 0.7);;
    color: black; */
  background-color: rgba(#4a4a4a, 0.08);
  color: #4a4a4a;
}

.makeCheck input:checked ~ .geekmark:after {
  display: block;
}

.makeCheck input:checked ~ .geekmark {
  background-color: #ff8c00;
}

// .makeCheck:hover {
//   color: #fff  ;
// }

.defaultSelected {
  background-color: rgba(#f37320, 0.16);
}

.defaultSelected:hover {
  background-color: rgba(#4a4a4a, 0.08);
}
.makeCheck {
  position: relative;
  //   padding-left: 24px  ;
  //   padding-top: 7.5px  ;
  //   padding-bottom: 7.5px  ;
  /* margin-bottom: 6px; */
  cursor: pointer;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px 8px 10px;
}

.hideCheckBox {
  display: none;
}

.imageOfDropDown {
  //   float: right;
  //   margin-right: 29px;
  //   margin-top: 6px;
  //   height: 8px;
  //   width:11px;
}

.imageOfDropDown:hover {
  color: #fff;
}
