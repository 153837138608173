.datepickerDiv {
  position: relative;
  // margin-top: 20px;
  //border-radius: 7px;
  border: 0;
  border-bottom: 1.7px solid #9f9e9e;
  // display: flex;
}

.datepickerDiv:hover {
  cursor: pointer;
}

#datepicker:focus {
  outline: none !important;
}
#datepicker:hover {
  cursor: pointer;
}

.datepickerDiv img {
  position: absolute;
  bottom: 5px;
  right: 0px;
}

#datepicker::placeholder {
  // color: #00000061;
  opacity: 1; /* Firefox */
  font-size: 16px;
}

#datepicker {
  border-radius: 7px;
  border-color: #fff;
  // padding-left: 18px;
  //   font-size: 16px;
  background: #fff;
  display: block;
  width: 100%;
  height: 100%;
  // color: #00000061;
  outline: none;
  z-index: 1;
  background: transparent;
}
.datepicker {
  position: relative;
}

#datepicker {
  &::placeholder {
    font-size: 12px;
    font-weight: bold;
  }

  &::-webkit-input-placeholder {
    font-size: 12px;
    font-weight: bold;
  }
}

.calender {
  background: #fff;
  box-sizing: border-box;
  position: absolute;
  width: 250px;
  height: 230px;
  border: 1px solid #eee;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  font-family: Helvetica, sans-serif;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 6px;
  padding: 8px;
}
.calender > .sp-cal-title {
  padding-bottom: 10px;
  font-weight: bolder;
  font-size: 14px;
  height: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.calender > .sp-cal-title > a {
  cursor: pointer;
  user-select: none;
}
.calender > .sp-cal-days {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  align-items: center;
  color: #777;
}

.calender > .sp-cal-days > a {
  padding: 3px 3px 3px 3px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  justify-self: center;
  align-self: center;
  text-align: center;
  cursor: pointer;
}
.calender > .sp-cal-days > a:hover {
  background-color: #bbdefb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.calender > .sp-cal-label-days {
  height: 16px;
  font-size: 12px;
  font-weight: bolder;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  align-items: center;
  color: #2979ff;
}

.calender > .sp-cal-label-days > span {
  justify-self: center;
  align-self: center;
}

.calender > .sp-cal-label-days > span:nth-child(1) {
  color: rgb(252, 67, 67);
}
.sp-cal-today {
  color: #333;
}
