$borderColor: #9f9e9e;
$initialPlaveHolderColor: #43425d;
$brandColor: #f37320;
$success: #13c333;

* {
  font-family: "Roboto", -apple-system, "Lucida Grande", Lucida, Arial, Verdana,
    sans-serif;
  font-style: normal;
  font-size: 12px;
  color: #414141;
}

input[type="text"],
input[type="email"] {
  width: 183px;
  opacity: 1;
  margin: 0;
  display: block;
  border: none;
  outline: none;
  border-bottom: 1.7px solid $borderColor;
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  // &::placeholder,
  // &::-webkit-input-placeholder {
  //   color: $initialPlaveHolderColor;
  //   font-size: 11px;
  //   margin-bottom: 20px;
  // }
  // &:focus {
  //   background-position: 0 0;
  //   &::placeholder,
  //   &::-webkit-input-placeholder {
  //     color: #999999;
  //     font-size: 11px;
  //     padding-bottom: 32px;
  //     transform: translateY(-20px);
  //     transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  //     visibility: visible !important;
  //   }
  // }
}

input[type="submit"] {
  background-color: white;
  color: #ff7141;
  width: 161px;
  height: 40px;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  border: #ff7141 solid 1px;
  border-radius: 3px;
  cursor: pointer;
  &:focus,
  &:hover {
    background-color: #ff7141;
    color: white;
  }
}

button {
  background-color: white;
  color: #ff7141;
  width: 161px;
  height: 40px;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  border: #ff7141 solid 1px;
  border-radius: 3px;
  cursor: pointer;
  &:focus,
  &:hover {
    background-color: #ff7141;
    color: white;
  }
}

select {
  width: 189px;
  display: inline-block;
  border: none;
  outline: none;
  font-size: 13px;
  opacity: 1;
  border-bottom: 1.7px solid $borderColor;
}

.outerBorder {
  border: 1px solid rgba(#707070, 0.12);
  box-shadow: 0px 0px 8px #00000029;
  margin-top: 20px;
  margin-bottom: 20px;
}

.borderHeader {
  background-color: rgba(#f4f4f4, 0.7);
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 17px;
  color: $brandColor;
  font-size: 14px;
  font-weight: bold;
}
a {
  color: #43425d;
}

.sectionHeader {
  font-size: 14px;
  font-weight: bold;
}
.sectionContent {
  margin: 10px 0px;
}

.width10 {
  width: 10%;
}

.width5 {
  width: 5%;
}

.width15 {
  width: 15%;
}

.padding10 {
  padding: 10px;
}

.padding23 {
  padding: 25px;
}

.header {
  border: 1px;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.popup {
  position: absolute;
  top: 38%;
  left: 37%;
  width: 25%;
  height: 23%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 18px;
  z-index: 10;
  box-shadow: "0 0.07143rem 0.14286rem 0 rgb(0 0 0 / 50%)";
}
.greyBack {
  background-color: #77716f;
  z-index: 5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0.35;
}

.hide {
  display: none;
}

.horizontalDivision {
  border-bottom: 1.7px solid #d1d1d1;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.mainThresholdSetting {
  margin-right: 30%;
}

.boxShadow {
  box-shadow: 0px 0px 4px #9a9ba2;
}

.margin10 {
  margin: 10px;
}

.flexStartAlign {
  align-items: flex-start;
}

.opsCategory {
  color: $brandColor;
  font-weight: bold;
  border-bottom: 1px solid rgba(#707070, 0.21);
  height: 21px;
}

.inprogress {
  background-color: #f4f4f4 !important;
  border: #f4f4f4 1px solid !important;
  color: $brandColor !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  cursor: initial;
}

.spinner {
  width: 25px;
  height: 25px;
  display: inline-block;
  border: 2px solid;
  border-color: #f37321 #f4f4f4 #f4f4f4 #f4f4f4;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
  transform: translate3d(0, 0, 0);
  -webkit-animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
    // border-color: #f4f4f4 #f4f4f4 #f4f4f4 #f4f4f4;
  }
  // 25% {
  //   transform: translate3d(0, 0, 0) rotate(360deg);
  //   // border-color: #f37321 #f4f4f4 #f4f4f4 #f4f4f4;
  // }
  // 55% {
  //   transform: translate3d(0, 0, 0) rotate(360deg);
  //   // border-color: #f37321 #f37321 #f4f4f4 #f4f4f4;
  // }
  // 75% {
  //   transform: translate3d(0, 0, 0) rotate(360deg);
  //   border-color: #f37321 #f37321 #f37321 #f4f4f4;
  // }
  100% {
    transform: translate3d(0, 0, 0) rotate(360deg);
    // border-color: #f37321 #f37321 #f37321 #f37321;
  }
}

.success {
  background-color: $success;
  border: 1px solid $success;
  color: $success;
  &:focus,
  &:hover {
    background-color: $success;
    border: 1px solid $success;
    color: $success;
  }
}

.datepicker-container {
  width: 189px;
}

.dateSection {
  margin-top: -4px !important;
}

.datePick {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1.7px solid #9f9e9e;
  width: 189px;
}

.datePick:focus {
  border-bottom: 1.7px solid #9f9e9e;
  width: 189px;
  outline: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
